.filter {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-family: 'Outfit', 'sans-serif';
  padding: 0;
  flex-direction: row;
  column-gap: 10px;
  margin: 0;
  gap: 10px;
}

.filter .filter-header {
  padding: 0;
  width: 100%;
  font-size: 24px;
  color: #002147;
}

.filter .filter-option {
  padding: 20px 25px;
}

.dropdown-options input {
  border: 1px solid #002147;
  border-radius: 4px;
  padding: 4px 9px;
  font-size: 12px;
}

.dropdown-option {
  font-size: 20px;
}
