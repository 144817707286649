h2.content_tc-header {
  font-size: 1.8rem;
}

.content_tc-body {
  max-width: 900px;
  margin: 0 auto;
  padding: 80px 20px;
}

.content_tc-body-text {
  line-height: 1.5;
  margin: 18px 0;
}

.content_tc-subheader {
  margin: 24px 0;
  font-size: 1.2rem;
}

span {
  font-weight: bold;
}

.content_container-contact {
  padding-left: 36px;
}

.content_contact-info {
  margin: 0;
}
