* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: 0.3s ease-in;
}

/* Root Styles
================================= */
:root {
  --primary-color: #002147;
  --secondary-color: #f36b3b;
  --secondary-color-darker: #e86231;
  --accent-color: #a8dadc;
  --black: #000000;
  --white: #ffffff;
}

/* General Styles
================================= */
.container {
  max-width: 1200px;
  margin: 0 auto;
}

.container-1040 {
  max-width: 1040px;
  margin: 0 auto;
}

h2 {
  font-family: 'Outfit', 'sans-serif';
  font-size: 4rem;
  font-weight: 800;
  color: var(--primary-color);
}

h3 {
  font-family: 'Outfit', 'sans-serif';
  font-size: 3rem;
  font-weight: 700;
  color: var(--primary-color);
}

.color-secondary {
  color: var(--secondary-color);
}

p {
  font-family: 'Outfit', 'sans-serif';
  font-size: 1.25rem;
  font-weight: 300;
  line-height: 1.4;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.mb-2 {
  margin-bottom: 1.25rem;
}

.d-flex-row-rev {
  flex-direction: row-reverse;
}

.section-header {
  text-align: center;
}

.section-header h3 {
  color: var(--secondary-color);
}

.container-1040 {
  max-width: 1040px;
}

@media screen and (max-width: 768px) {
  h2 {
    font-size: 2.875rem;
    text-align: center;
  }

  h3 {
    font-size: 1.875rem;
  }

  p {
    font-size: 1rem;
  }

  .section .container-1040 {
    row-gap: 40px;
  }
}
