.content__thank-you {
  background-color: var(--white);
  padding: 80px 20px;
  height: max-content;
}

.content__thank-you .container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.content__thank-you .container h2 {
  margin-bottom: 70px;

  color: #323232;
}

.content__thank-you .container h3 {
  color: #f36b3b;
  line-height: 1.75;
}

.content__thank-you .container p {
  margin: 0 0 30px;
}
