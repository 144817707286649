.content__welcome-intro {
  background-color: var(--white);
  padding: 80px 20px;
  height: max-content;
}

.content__welcome-intro .container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.content__welcome-intro .container h2 {
  margin-bottom: 50px;
}

.content__welcome-intro .container p {
  margin: 0 0 30px;
}

.content__welcome-selection {
  background-color: var(--accent-color);
  padding: 40px 20px;
}

.content__welcome-selection .container {
  padding-top: 0;
  padding-bottom: 0;
}

.content__welcome-buttons {
  display: flex;
  justify-content: space-between;
  max-width: 720px;
  margin: 50px auto;
  column-gap: 20px;
}

.content__welcome-annotations {
  max-width: 720px;
  width: 100%;
  margin: 0 auto;
}

.content__welcome-annotations p {
  font-size: 1rem;
  font-weight: 300;
}

@media screen and (max-width: 767px) {
  .content__welcome-buttons {
    flex-direction: column;
    max-width: 100%;
    gap: 1.25rem;
  }
}

@media screen and (max-width: 768px) {
  .content__welcome-intro .container > img {
    max-width: 100%;
  }

  .content__welcome-selection {
    padding: 10px 20px;
  }
}
