section.search-results {
  padding: 80px 20px;
}

section.content {
  padding: 0 60px;
}

section.search-results .container-1040 {
  display: flex;
  flex-direction: column;
  row-gap: 44px;
}

@media (min-width: 0px) {
  .css-1r44ycj {
    gap: 40px !important;
  }
}

@media (max-width: 768px) {
  section.search-results {
    padding: 60px 20px;
    row-gap: 40px;
  }

  section.search-results .container-1040 {
    row-gap: 40px;
  }

  section.content {
    padding: 0 0;
  }
  
}
