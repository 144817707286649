.button__welcome {
  width: 100%;
  text-decoration: none;
}

.button__welcome:hover {
  text-decoration: none;
}

.button__welcome-primary {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
  max-width: 340px;
  height: auto;
  width: 100%;
  background-color: var(--secondary-color);
  border: none;
  border-radius: 16px;
  padding: 20px;
  box-shadow: 5px 9px 0px 0px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}

.button__welcome-primary:hover {
  background-color: var(--secondary-color-darker);
  box-shadow: 3px 6px 0px 0px rgba(33, 33, 33, 0.25);
}

.button__welcome-primary img {
  margin: 30px 0 25px;
}

.button__welcome-primary p {
  font-family: 'Roboto', sans-serif;
  font-size: 2.25rem;
  font-weight: 700;
  color: var(--white);
  line-height: 1.1;
}

.button__welcome-primary span {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.19);
  border-radius: 8px;
  padding: 15px;
  font-family: 'Outfit', sans-serif;
  font-size: 1rem;
  font-weight: 300;
  color: var(--white);
}

@media screen and (max-width: 767px) {
  .button__welcome-primary {
    /* max-height: 200px; */
  }
}

@media screen and (max-width: 768px) {
  .button__welcome-primary {
    padding: 20px;
    max-width: 100%;
  }

  .button__welcome-primary p {
    margin: 0;
    font-size: 1.3rem;
    word-break: break-all;
  }

  .button__welcome-primary img {
    width: 20%;
  }

  .button__welcome-primary span {
    font-size: 1rem;
    padding: 7px;
  }
}

@media screen and (max-width: 390px) {
  .button__welcome-primary {
    gap: 10px;
  }

  .button__welcome-primary p {
    font-size: 1.2rem;
  }

  .button__welcome-primary img {
    margin: 20px 0 10px;
  }

  .button__welcome-primary span {
    font-size: 0.9rem;
  }
}
