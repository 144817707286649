button {
  border: none;
  cursor: pointer;

  background: transparent;
}

button img {
  width: 100%;
}

.banner__container {
  text-align: center;
}

/* Media Queries for responsiveness */


@media screen and (max-width: 990px) {
  button img {
    width: 100%;
  }
}

@media screen and (max-width: 375px) {
  button img {
    width: 100%;
  }
}

@media screen and (max-width: 321px) {
  button img {
    width: 270px;
  }
}