.featureGuides {
  background: var(--accent-color);
  padding-top: 70px;
  padding-bottom: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.featureGuides .container-1040 {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.featureGuides h2 {
  font-size: 3.25rem;
  text-align: left;
}

.featureGuides h3 {
  font-size: 2.5rem;
  margin-top: 40px;
}

.featureGuides__feature {
  display: flex;
  column-gap: 111px;
  width: '100%';
}

.featureGuides__feature-img {
  width: 34%;
}

.featureGuides__feature-text {
  width: 535px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* .featureGuides__feature-desc {
    color: #191919;
    font-family: var(--content-font);
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
} */

@media screen and (max-width: 375px) {
  .featureGuides h2 {
    font-size: 2rem;
  }
}
@media screen and (min-width: 376) and (max-width: 1024px) {
  .featureGuides {
    padding-left: 50px;
    padding-right: 50px;
  }

  .featureGuides__feature {
    flex-direction: column-reverse;
    align-items: center;
  }
  .featureGuides__feature-img {
    margin-bottom: 40px;
    max-width: 370px;
    width: 100%;
  }
  .featureGuides__feature-desc {
    text-align: justify;
  }
  .container-1040 h2 {
    text-align: left;
  }
}

@media screen and (max-width: 390px) {
  .featureGuides {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

@media screen and (max-width: 1024px) {
  .featureGuides h2{
    font-size: 2.5rem;
  }
}

@media screen and (max-width: 900px) {
  .featureGuides__feature-img {
    width: 100%;
    max-width: 300px;
  }

  .featureGuides h3 {
    font-size: 1.7rem;
  }
  
  .featureGuides .container-1040 {
    row-gap: 30px;
  }
  
}
