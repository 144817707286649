.section__welcome {
  background: url('../../public/images/graduate-student.jpg') center bottom no-repeat;
  background-size: cover;
  height: max-content;
  padding: 110px 0;
  height: 780px;
}

.section__welcome__student {
  background-position: center right !important;
}

.section__welcome__provider {
  background: url('../../public/images/graduation-students.jpg') center no-repeat;
  background-size: cover;
  padding: 70px 20px !important;
}

.section__welcome .container {
  padding-top: 0;
  padding-bottom: 0;
  letter-spacing: 0.3px;
}

.section__welcome h2 {
  font-size: 3.25rem;
  font-weight: 700;
  color: var(--white);
  text-align: center;
  margin-bottom: 30px;
  /* text-shadow: -1px 1px 10px #505050; */
  /* color: #0e2f71; */
}

.section__welcome h2 .h-subtext-gradient {
  background: -webkit-linear-gradient(#A8DADC 40%, #0075FF) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  color: unset !important; 
}

.section__welcome h2 .h-subtext {
  text-shadow: none !important;
  background: -webkit-linear-gradient(#e86231, #f36b3b 50%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: unset;
}

.section__welcome h3 {
  max-width: 800px;
  margin: 0 auto;
  font-size: 2rem;
  font-weight: 500;
  color: var(--white);
  text-align: center;
  line-height: 1.3;
  text-shadow: -1px 1px 10px #505050;
}

.section__welcome h4 {
  max-width: 800px;
  margin: 20px auto 0;
  font-size: 1.5rem;
  font-weight: 500;
  color: var(--white);
  text-align: center;
  line-height: 1.5;
  text-shadow: -1px 1px 10px #505050;
}

.section__buttons {
  margin-top: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  column-gap: 20px;
}

.section__buttons button {
  max-width: 210px; 
}

.section__buttons__student button {
  max-width: 243px !important;
}

@media screen and (max-width: 375px) {
  .section__welcome h2 {
    font-size: 2rem !important;
    margin-bottom: 40px !important;
  }
  .section__buttons { 
    margin-top: 30px !important;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
  }
}
@media screen and (max-width: 767px) {
  .section__welcome__provider {
    background: url('../../public/images/graduation-students.jpg');
    background-position: center center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .section__welcome__provider {
    background: url('../../public/images/graduation-students.jpg');
    background-position: center;
    background-size: 120% 100% !important;
    background-repeat: no-repeat !important;
  }
}

@media screen and (max-width: 768px) {
  .section__welcome.section__welcome__provider {
    padding: 80px 20px !important;
  }
  .section__welcome.section__welcome__provider h2 {
    margin-bottom: 30px !important;
  }
  .section__buttons.section__welcome__provider {
    margin-top: 25px !important;
    row-gap: 10px !important;
  }
}
@media screen and (max-width: 1024px) {
  .section__welcome {
    height: fit-content;
    padding: 100px 20px;
    height: 90vh;
    padding: 95px 20px !important;
  }

  .section__welcome h2 {
    font-size: 2.5rem;
    margin-bottom: 40px;
    background: -webkit-linear-gradient(#A8DADC 40%, #0075FF);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .section__welcome h3 {
    font-size: 1.25rem;
    margin: 0 auto 25px
  }

  .section__welcome h4 {
    font-size: 1rem;
    margin-top: 0;
    text-align: justify;
  }

  .section__buttons {
    margin-top: 55px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .section__welcome {
      height: auto;
  }
  .section__welcome h4 {
    text-align: center;
    max-width: 550px;
  }
}

@media screen and (min-width: 1024px) {
  .section__welcome {
    padding: 144px 20px !important;
  }
  .section__welcome.section__welcome__provider h2{
    letter-spacing: -0.2px;
  }
}
